const useDialog = () => {
  const showModuleInfo = useState('showModuleInfo', () => ({
    testing: false,
    analytics: false,
    plan: false,
  }))
  const serviceDialog = useState('serviceDialog', () => false)
  const serviceSlug = useState('serviceSlug', () => undefined)
  const protocolId = useState('protocolId', () => undefined)
  const quoteId = useState('quoteId', () => undefined)
  const quoteNote = useState('quoteNote', () => undefined)
  const quoteNoteDialog = useState('quoteNoteDialog', () => false)
  const quoteLink = useState('quoteLink', () => undefined)
  const quoteLinkDialog = useState('quoteLinkDialog', () => false)
  const scheduleDialog = useState('scheduleDialog', () => false)
  const scheduleInfo = useState('scheduleInfo', () => null)
  const removeServiceDialog = useState('removeServiceDialog', () => false)
  const confirmNotificationDialog = useState('confirmNotificationDialog', () => false)
  const isBioMarkerInfoOpen = useState('isBioMarkerInfoOpen', () => false)

  function toggleModuleInfo(module) {
    showModuleInfo.value[module] = !showModuleInfo.value[module]
  }

  function openDialog(slug, id = 0) {
    serviceSlug.value = slug
    protocolId.value = id
    serviceDialog.value = true
  }

  function openServiceRemoveDialog(id) {
    protocolId.value = id
    removeServiceDialog.value = true
  }

  function openNotificationDialog(id) {
    protocolId.value = id
    confirmNotificationDialog.value = true
  }

  function openQuoteNoteDialog(id, text) {
    quoteId.value = id
    quoteNote.value = text
    quoteNoteDialog.value = true
  }

  function openQuoteLinkDialog(id, url) {
    quoteId.value = id
    quoteLink.value = url
    quoteLinkDialog.value = true
  }

  function toggleBioMarkerInfo() {
    isBioMarkerInfoOpen.value = !isBioMarkerInfoOpen.value
  }

  return {
    showModuleInfo,
    toggleModuleInfo,
    serviceDialog,
    serviceSlug,
    openDialog,
    removeServiceDialog,
    protocolId,
    openServiceRemoveDialog,
    confirmNotificationDialog,
    openNotificationDialog,
    scheduleDialog,
    scheduleInfo,
    quoteId,
    quoteNote,
    quoteNoteDialog,
    openQuoteNoteDialog,
    quoteLink,
    quoteLinkDialog,
    openQuoteLinkDialog,
    isBioMarkerInfoOpen,
    toggleBioMarkerInfo,
  }
}

export default useDialog